import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import downtown from "../images/backrounds/san-francisco-darker.jpg";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Nonprofits"
        description="Our teams love to support nonprofits by sharing our knowledge and experience in the industry to make your message shine and your business excel."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${downtown})`,
          }}
        >
          <div
            className="hero-title bg-primary-transparent"
            style={{ backgroundColor: "rgba(76,134,160,0.75)" }}
          >
            <h1 className="text-white">Nonprofit Organizations</h1>
            <p className="text-white">
              Providing design, development, maintenance, operations, and
              consulting for the unique needs of nonprofit organizations, all in
              one place.
            </p>
            <CTA
              href="/contact-us/"
              text="Contact us today"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section className="mt-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                We Can Help Create and Build the Perfect Website or Platform
              </h2>
              <p>
                Nonprofits face a unique set of challenges when they are looking
                to build out a web presence, pump up their marketing, or adopt
                new tools. Nonprofits have to keep costs in mind while still
                achieving a dynamic and interesting presence and need solutions
                that can be kept up through their team in the long run.
              </p>
              <p>
                Whether you need to build a website, create a marketing plan,
                advertise an event, present an annual report, or even just print
                simple brochures, business cards, and presentations – 729
                Solutions can lead the way while teaching your team how to plan
                and execute for the future.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row my-5">
            <div className="col-12 col-lg-6">
              <h3>We can help with:</h3>
              <ul className="text-primary mt-5">
                <li className="mb-3">
                  <span className="text-dark">
                    Designing and building responsive websites
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Designing and building mobile apps
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Logos, branding and style guides
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">Annual reports</span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">Presentations</span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">Funding pitch decks</span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Social media marketing collateral
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Print marketing such as flyers, brochures, business cards,
                    and more!
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0">
              <div className="row d-flex align-items-center">
                <div className="col-12">
                  <h3>Some nonprofits we have worked with:</h3>
                </div>
                <div className="col-6 col-sm-4 my-3">
                  <StaticImage
                    src="../images/nonprofit/art-in-action.png"
                    alt="Art in Action"
                  />
                </div>
                <div className="col-6 col-sm-4 my-3">
                  <StaticImage
                    src="../images/nonprofit/san-diego-county.jpg"
                    alt="San Diego County Chapter"
                  />
                </div>
                <div className="col-6 col-sm-4 my-3">
                  <StaticImage
                    src="../images/nonprofit/outdoor-outreach.jpg"
                    alt="Outdoor Outreach"
                  />
                </div>
                <div className="col-6 col-sm-4 my-3">
                  <StaticImage
                    src="../images/nonprofit/raft.png"
                    alt="Raft resource area for teaching"
                  />
                </div>
                <div className="col-6 col-sm-4 my-3">
                  <StaticImage
                    src="../images/nonprofit/4cs.jpg"
                    alt="4Cs Child Care Coordinating Council"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="container">
          <div className="row">
            <div className="col-12 bg-gray-200 my-5 py-5">
              <h2>
                We have the design knowledge and the development skills to execute your project, large or small.
              </h2>
              <p>
                729 Solutions can be a one-stop-shop to get your nonprofit where
                it needs to be.
              </p>
              <div className="text-center mt-6">
                <CTA
                  href="/contact-us/"
                  text="See More Of What We Do"
                  className="btn btn-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="container pt-5 bg-primary-alternative">
          <div className="row text-center">
            <div className="col-12 text-white">
              <h2 className="text-white mb-2">Contact Us Today</h2>
              <p>
                We have the design knowledge and the development skills to
                execute your project, large or small. 729 Solutions can be a
                one-stop-shop to get your nonprofit where it needs to be.
              </p>
              <p>
                You can also send an email to{" "}
                <a href="mailto:graham@729.io?subject=Managed Service Provider" className="text-secondary-light">
                  graham@729.io
                </a>{" "}
                to schedule a time to talk about how best to work together.
              </p>
            </div>
            <div className="col-12 my-5">
              <CTA
                href="/contact-us/"
                text="Schedule A Time To Talk"
                className="btn btn-secondary-light"
              />
            </div>
          </div>
        </div>
      </section>
      <BlogFeed />
    </Layout>
  );
};
export default Index;
